import { shallowEqual } from 'react-redux';

import { Film } from '@app/api/resources/Film';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import useI18nUrlData, { I18nUrlData } from '@app/hooks/helpers/useI18nUrlData';
import { OnboardingTriggerFunc } from '@app/hooks/helpers/useOnboardingTrigger';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const getAction = (
  film: Film,
  isAuthenticated: boolean,
  activeSubscriber: boolean,
  onboardingTrigger: OnboardingTriggerFunc,
  i18nUrlData: I18nUrlData,
): { href: string } | { onClick: () => void } => {
  const redirectUrl = getFullRouteUrl({
    url: `/films/${film.id}/player`,
    i18nUrlData,
    includeDomain: false,
  });
  const watchAction = {
    href: redirectUrl,
  };
  const [consumableOffer] = film.consumable.offered;
  if (consumableOffer.type === 'free') {
    if (isAuthenticated) {
      return watchAction;
    }
    if (!isAuthenticated) {
      return {
        onClick: () =>
          onboardingTrigger('free', {
            contextData: {
              redirectUrl,
            },
          }),
      };
    }
  }
  if (consumableOffer.type === 'catalogue') {
    if (activeSubscriber) {
      return watchAction;
    }
    if (!activeSubscriber) {
      return {
        onClick: () =>
          onboardingTrigger('catalogue', {
            contextData: {
              redirectUrl,
              hideEligibleForTrialMessage: true,
            },
          }),
      };
    }
  }
  return null;
};

const usePlayActionForConsumable = (
  film: Film,
  onboardingTrigger: OnboardingTriggerFunc,
): { href?: string; onClick?: () => void } => {
  const i18nUrlData = useI18nUrlData();
  const { isAuthenticated, activeSubscriber } = useAppSelector(
    state => ({
      isAuthenticated: state.user.isAuthenticated,
      activeSubscriber: state.user.activeSubscriber,
    }),
    shallowEqual,
  );

  if (
    !film ||
    film.consumable?.availability !== 'live' ||
    !Array.isArray(film.consumable.offered)
  ) {
    return null;
  }

  const action = getAction(
    film,
    isAuthenticated,
    activeSubscriber,
    onboardingTrigger,
    i18nUrlData,
  );

  return action;
};

export default usePlayActionForConsumable;
