import HttpService from '@app/api/http-service';
import { ObjectOfStrings } from '@app/api/utility-types';

export const createTrailerViewing = (
  httpContext: ObjectOfStrings,
  trailerId: number,
): Promise<{
  status: 201;
}> =>
  HttpService(httpContext).post('/trailer_viewings', {
    data: {
      trailer_id: trailerId,
    },
  });
