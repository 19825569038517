import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { isClient } from '@app/services/utils';

const useWindowSize = () => {
  function getSize() {
    return {
      width: isClient() ? window.innerWidth : null,
      height: isClient() ? window.innerHeight : null,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = debounce(() => {
    setWindowSize(getSize());
  }, 50);

  useEffect(() => {
    if (isClient()) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export default useWindowSize;
