import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { PlayerControlInterface } from '@app/services/player';

import {
  FullscreenCompressButtonIcon,
  FullscreenExpandButtonIcon,
} from '@app/components/icons/PlayerIcons';
import ElementOnHoverScaler from '@app/components/player/player-controls/ElementOnHoverScaler';

type FullScreenButtonProps = {
  player: PlayerControlInterface;
  toggleFullScreenState?: () => void;
};

const FullScreenButton = ({
  player,
  toggleFullScreenState,
}: FullScreenButtonProps) => {
  const { t } = useTranslation('film_player');
  const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(
    player.isFullscreenEnabled(),
  );

  useEffect(() => {
    window.addEventListener('fullscreenchange', onToggleFullScreenState);
    window.addEventListener('mozfullscreenchange', onToggleFullScreenState);
    window.addEventListener('MSFullscreenChange', onToggleFullScreenState);
    window.addEventListener('webkitfullscreenchange', onToggleFullScreenState);

    return () => {
      window.removeEventListener('fullscreenchange', onToggleFullScreenState);
      window.removeEventListener(
        'mozfullscreenchange',
        onToggleFullScreenState,
      );
      window.removeEventListener('MSFullscreenChange', onToggleFullScreenState);
      window.removeEventListener(
        'webkitfullscreenchange',
        onToggleFullScreenState,
      );
    };
  });

  const onToggleFullScreenState = () => {
    setIsFullscreenEnabled(player.isFullscreenEnabled());
    toggleFullScreenState();
  };

  const onClickToggleFullScreen = () => {
    if (player.isFullscreenEnabled()) {
      player.doExitFullscreen();
    } else {
      player.doEnterFullscreen();
    }
  };

  const iconTitle = player.isFullscreenEnabled()
    ? t('film_player:film_player.fullscreen_exit')
    : t('film_player:film_player.fullscreen_enter');

  return (
    <Container>
      <FullScreenButtonContainer
        onClick={onClickToggleFullScreen}
        title={iconTitle}
      >
        <ElementOnHoverScaler>
          {isFullscreenEnabled ? (
            <FullscreenCompressButtonIcon width="16px" />
          ) : (
            <FullscreenExpandButtonIcon width="16px" />
          )}
        </ElementOnHoverScaler>
      </FullScreenButtonContainer>
    </Container>
  );
};

export default FullScreenButton;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenButtonContainer = styled.div`
  cursor: pointer;
`;
