import { ReactNode, SetStateAction, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';

import {
  getFacebookShareUrl,
  getTwitterShareUrl,
} from '@app/services/social-media';
import { addUrlParam } from '@app/services/utils';

import { color as themeColors } from '@app/themes/mubi-theme';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import FadeIn from '@app/components/FadeIn';
import { CloseIcon } from '@app/components/icons/CommonIcons';
import { ShareArrowIcon } from '@app/components/icons/PlayerIcons';
import SocialButtons from '@app/components/SocialButtons';

const colorThemes = {
  dark: {
    main: themeColors.black,
    hover: themeColors.midGray,
  },
  light: {
    main: themeColors.white,
    hover: themeColors.midGray,
  },
} as const;

type colorThemesTypes = keyof typeof colorThemes;

type ShareButtonsProps = {
  url: string;
  shareCopy: string;
  snowplowElement: string;
  shareIcon?: ReactNode;
  showShareText?: boolean;
  shareText?: string;
  useNativeShare?: boolean;
  nativeShareParams?: {
    title?: string;
    text: string;
    url: string;
  };
  theme?: colorThemesTypes;
  onOpenChange?: (x: SetStateAction<boolean>) => void;
  trackShareButtonClick?: (clickType: string, element: string) => void;
  fontWeight?: number;
};

const ShareButtons = ({
  url,
  shareCopy,
  snowplowElement,
  shareIcon = null,
  showShareText = true,
  shareText = null,
  useNativeShare = false,
  nativeShareParams = {
    text: '',
    url: '',
  },
  theme = 'light',
  onOpenChange = () => {},
  trackShareButtonClick = () => {},
  fontWeight = 500,
}: ShareButtonsProps) => {
  const { t } = useTranslation('common');
  const isMobile = useAppSelector(state => state.appState.isMobile);
  const [isOpen, setIsOpen] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [browserSupportsNativeShare, setBrowserSupportsNativeShare] =
    useState(null);
  const [whichButtonToShowInitialised, setWhichButtonToShowInitialised] =
    useState(false);

  useEffect(() => {
    setBrowserSupportsNativeShare(Boolean(navigator?.share));
    setWhichButtonToShowInitialised(true);
  }, []);

  const showNativeShareButton = isMobile && browserSupportsNativeShare;

  const themeButton = Object.keys(colorThemes).includes(theme)
    ? colorThemes[theme]
    : colorThemes.light;

  const openIfUnopened = () => {
    if (!isOpen) {
      trackEvent('show_share_buttons');
      onOpenChange(true);
      setIsOpen(true);
      setTimeout(() => {
        setShowButtons(true);
      }, 120);
    }
  };

  const closeShareOptions = () => {
    setShowButtons(false);
    trackEvent('hide_share_buttons');
    setTimeout(() => {
      onOpenChange(false);
      setIsOpen(false);
    }, 60);
  };

  const trackEvent = (eventType: string) => {
    trackShareButtonClick(eventType, snowplowElement);
  };

  const handleNativeShare = async () => {
    try {
      await navigator.share(nativeShareParams);
      trackEvent('recommend_link_native');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (!whichButtonToShowInitialised) {
    return null;
  }

  return (
    <>
      {useNativeShare && showNativeShareButton ? (
        <Container onClick={handleNativeShare}>
          {showShareText ? (
            <ShareWord isOpen={isOpen} fontWeight={fontWeight}>
              {shareText ? shareText : t('common:common.buttons.share')}
            </ShareWord>
          ) : null}
          <ShareIconContainer>
            {shareIcon ? (
              shareIcon
            ) : (
              <ShareArrowIcon width="23px" color={themeButton.main} />
            )}
          </ShareIconContainer>
        </Container>
      ) : (
        <Container onClick={openIfUnopened}>
          {showShareText ? (
            <ShareWord isOpen={isOpen} fontWeight={fontWeight}>
              {shareText ? shareText : t('common:common.buttons.share')}
            </ShareWord>
          ) : null}
          {showButtons && (
            <FadeIn isShowing>
              <SocialButtonsContainer>
                <SocialButtons
                  spaceBetweenIconsDesktop="17px"
                  spaceBetweenIconsWide="17px"
                  spaceBetweenIconsTablet="17px"
                  spaceBetweenIconsMobile="17px"
                  iconWidth="22px"
                  facebookUrl={getFacebookShareUrl(url)}
                  twitterUrl={getTwitterShareUrl(
                    addUrlParam(url, 'utm_source=twitter'),
                    shareCopy,
                  )}
                  shareLinkUrl={url}
                  iconColor={themeButton.main}
                  iconColorHoverOverride={themeButton.hover}
                  trackEvent={trackEvent}
                />
              </SocialButtonsContainer>
            </FadeIn>
          )}
          {!isOpen && (
            <ShareIconContainer>
              {shareIcon ? (
                shareIcon
              ) : (
                <ShareArrowIcon width="23px" color={themeButton.main} />
              )}
            </ShareIconContainer>
          )}

          {isOpen && (
            <CloseIconContainer onClick={closeShareOptions}>
              <CloseIcon width="14px" color={themeButton.main} />
            </CloseIconContainer>
          )}
        </Container>
      )}
    </>
  );
};

export default ShareButtons;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
`;

const SocialButtonsContainer = styled.div`
  margin-left: 7px;
`;

type ShareWordProps = {
  fontWeight: number;
  isOpen: boolean;
};
const ShareWord = styled.div<ShareWordProps>`
  position: absolute;
  color: ${props => props.theme.color.white};
  font-size: 16px;
  font-weight: ${props => props.fontWeight};
  right: 0;

  transition: transform 0.2s;
  transform: ${props =>
    props.isOpen ? 'translateX(-149px)' : 'translateX(-29px)'};
`;
const ShareIconContainer = styled.div``;
const CloseIconContainer = styled.div`
  margin-left: 17px;
`;
