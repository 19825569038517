import { MutableRefObject, useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import mux from 'mux-embed';

import { Film } from '@app/api/resources/Film';
import { createTrailerViewing } from '@app/api/resources/Trailer';

import { areAnalyticsCookiesAllowed } from '@app/services/cookie-consent';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import { getIsFilmTrailer } from '@app/components/player/TrailerPlayer';

import packageInfo from '@app/package.json';

const useFilmTrailerPlayerTracking = (
  film: Film,
  videoNode: MutableRefObject<HTMLVideoElement>,
): {
  onVideoStarted: () => Promise<void>;
  doTrackTrailerEvent: (clickType: string, element?: string) => void;
} => {
  const isFilmTrailer = getIsFilmTrailer(film);

  const { user, httpContext, isAuthenticated } = useAppSelector(
    state => ({
      user: state.user.user,
      httpContext: state.appState.httpContext,
      isAuthenticated: state.user.isAuthenticated,
    }),
    shallowEqual,
  );

  const hasTrackedInitialPlayEvent = useRef(false);

  const trackSnowplowClickEvent = useSnowplowClickTracker();
  const doTrackTrailerEvent = (clickType: string, element?: string) => {
    const event: { clickType: string; element?: string } = {
      clickType,
    };
    if (typeof element === 'string') {
      event.element = element;
    }
    trackSnowplowClickEvent({ data: event, film });
  };

  useEffect(() => {
    const analyticsCookiesAllowed = areAnalyticsCookiesAllowed();
    if (analyticsCookiesAllowed && videoNode.current && isFilmTrailer) {
      mux.monitor(videoNode.current, {
        debug: false,
        beaconCollectionDomain: 'mux.mubi.com',
        data: {
          env_key: process.env.MUX_PROPERTY_KEY,
          player_name: 'MUBI-Web-Trailer-Player',
          player_init_time: Date.now(),
          player_version: packageInfo.version,
          viewer_user_id: user?.id,
          page_type: 'filmpage-trailer',
          sub_property_id: 'mubi-web',
          video_title: `${film.title}-trailer`,
          video_id: film.id,
          video_content_type: 'trailer',
          custom_1: 'trailer',
        },
      });
    }
  }, [videoNode]);

  const onVideoStarted = async () => {
    if (isFilmTrailer) {
      if (!hasTrackedInitialPlayEvent.current) {
        hasTrackedInitialPlayEvent.current = true;
        doTrackTrailerEvent('film_trailer_play');
        if (isAuthenticated) {
          await createTrailerViewing(httpContext, film?.trailer_id);
        }
      }
    }
  };
  return { onVideoStarted, doTrackTrailerEvent };
};

export default useFilmTrailerPlayerTracking;
