import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import usePlayActionForConsumable from '@app/hooks/helpers/usePlayActionForConsumable';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';

const PreviewClipEndOfClipOverlay = ({ film }: { film: Film }) => {
  const { t } = useTranslation('film_player');
  const onboardingTrigger = useOnboardingTrigger();

  const action = usePlayActionForConsumable(film, onboardingTrigger);

  return (
    <Container>
      <OpacityOverlay />
      {action?.onClick ? (
        <ButtonContainer onClick={e => e.stopPropagation()}>
          <MubiButton clickAction={action?.onClick}>
            {t('film_player:film_player.start_film')}
          </MubiButton>
        </ButtonContainer>
      ) : (
        action?.href && (
          <ButtonContainer>
            <MubiButtonLink url={action?.href} isSpaLink={false}>
              {t('film_player:film_player.start_film')}
            </MubiButtonLink>
          </ButtonContainer>
        )
      )}
    </Container>
  );
};

export default PreviewClipEndOfClipOverlay;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const OpacityOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.75);
`;

const ButtonContainer = styled.div`
  position: relative;
`;
