import { useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import isBot from 'isbot';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  Film,
  getResponsiveTrailerUrl as getTrailerUrlForFilm,
} from '@app/api/resources/Film';
import {
  FilmGroup,
  getFilmGroupTrailerUrlAtProfile as getTrailerUrlForFilmGroup,
} from '@app/api/resources/FilmGroup';

import { isGreaterThanMobileWidth } from '@app/services/breakpoints';
import { getControlInterfaceForHtmlVideo } from '@app/services/player';
import { getFullRouteUrl } from '@app/services/routeHelpers';

import { color as themeColors } from '@app/themes/mubi-theme';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';
import useFilmTrailerPlayerTracking from '@app/hooks/player/useFilmTrailerPlayerTracking';

import ShareButtons from '@app/components/buttons/ShareButtons';
import TextPlayButton from '@app/components/buttons/TextPlayButton';
import FadeIn from '@app/components/FadeIn';
import FadeInAndOut from '@app/components/FadeInAndOut';
import Loading from '@app/components/Loading';
import InPagePlayerKeyShortcuts from '@app/components/player/InPagePlayerKeyShortcuts';
import FullScreenButton from '@app/components/player/player-controls/FullScreenButton';
import ProgressBar from '@app/components/player/player-controls/ProgressBar';
import PreviewClipEndOfClipOverlay from '@app/components/player/PreviewClipEndOfClipOverlay';
import StopPropagation from '@app/components/StopPropagation';
import UserActiveContainer from '@app/components/visibility-containers/UserActiveContainer';

type TrailerPlayerProps = {
  modalIsClosed: boolean;
  filmOrFilmGroup: Film | FilmGroup;
};

const TrailerPlayer = ({
  filmOrFilmGroup,
  modalIsClosed,
}: TrailerPlayerProps) => {
  const { t } = useTranslation('common');

  const i18nUrlData = useI18nUrlData();
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hidePlayButton, setHidePlayButton] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isAtEndOfTrailer, setIsAtEndOfTrailer] = useState(false);
  const [shouldAutoplay, setShouldAutoplay] = useState(false);

  const [playerInterface, setPlayerInterface] = useState(null);

  const videoNode = useRef<HTMLVideoElement>(null);
  const videoContainerNode = useRef(null);

  const { onVideoStarted: doOnVideoStarted, doTrackTrailerEvent } =
    useFilmTrailerPlayerTracking(filmOrFilmGroup as Film, videoNode);

  const isFilmTrailer = getIsFilmTrailer(filmOrFilmGroup);

  const [trailerUrl] = useState(
    isFilmTrailer
      ? getTrailerUrlForFilm(filmOrFilmGroup as Film)
      : getTrailerUrlForFilmGroup(filmOrFilmGroup as FilmGroup, '720p'),
  );

  useEffect(() => {
    setPlayerInterface(
      getControlInterfaceForHtmlVideo(
        videoNode.current,
        videoContainerNode.current,
      ),
    );
  }, []);

  useEffect(() => {
    if (!modalIsClosed && !isBot(navigator.userAgent)) {
      setShouldAutoplay(true);
    } else {
      setShouldAutoplay(false);
    }
  }, [modalIsClosed]);

  useEffect(() => {
    if (isAtEndOfTrailer && isFilmTrailer) {
      if (playerInterface) {
        playerInterface.doExitFullscreen();
      }
    }
  }, [isAtEndOfTrailer, isFilmTrailer]);

  useEffect(() => {
    const onLoadedVideo = () => {
      setIsLoading(false);
    };
    const onVideoEnd = () => {
      setIsPlaying(false);
      setIsAtEndOfTrailer(true);
    };
    const onVideoStarted = async () => {
      if (typeof doOnVideoStarted === 'function') {
        await doOnVideoStarted();
      }
      setIsPlaying(true);
      setIsAtEndOfTrailer(false);
    };
    const onVideoPaused = () => {
      setIsPlaying(false);
    };
    const onSeeked = () => {
      setIsAtEndOfTrailer(false);
    };
    const videoNodeRef = videoNode.current;
    videoNodeRef.addEventListener('loadedmetadata', onLoadedVideo);
    videoNodeRef.addEventListener('canplay', onLoadedVideo);
    videoNodeRef.addEventListener('play', onVideoStarted);
    videoNodeRef.addEventListener('pause', onVideoPaused);
    videoNodeRef.addEventListener('ended', onVideoEnd);
    videoNodeRef.addEventListener('seeked', onSeeked);
    return () => {
      videoNodeRef.removeEventListener('loadedmetadata', onLoadedVideo);
      videoNodeRef.removeEventListener('canplay', onLoadedVideo);
      videoNodeRef.removeEventListener('play', onVideoStarted);
      videoNodeRef.removeEventListener('pause', onVideoPaused);
      videoNodeRef.removeEventListener('ended', onVideoEnd);
      videoNodeRef.removeEventListener('seeked', onSeeked);
    };
  }, []);

  const togglePlaying = () => {
    try {
      playerInterface.doTogglePlay();
    } catch (error) {
      // Ignoring error when browsers prevent play from occurring.
    }
  };

  const onShareButtonOpenChange = areShareButtonsShowing => {
    if (!isGreaterThanMobileWidth()) {
      setHidePlayButton(areShareButtonsShowing);
    } else if (hidePlayButton) {
      setHidePlayButton(false);
    }
    if (areShareButtonsShowing && typeof doTrackTrailerEvent === 'function') {
      doTrackTrailerEvent('film_trailer_share');
    }
  };

  const shareButtonsUrl = getFullRouteUrl({
    url: `/${isFilmTrailer ? 'films' : 'collections'}/${encodeURI(
      filmOrFilmGroup.slug,
    )}/trailer`,
    i18nUrlData,
  });
  const shareButtonsCopy = `Trailer: ‘${filmOrFilmGroup.title}’`;

  const isPlayerFullscreen = playerInterface?.isFullscreenEnabled();

  const isFullscreenSupported = playerInterface?.isFullscreenSupported();

  return (
    <StyledStopPropagation>
      <InPagePlayerKeyShortcuts
        userHasInteractedWithPlayer
        playerControlInterface={playerInterface}
      >
        <UserActiveContainer>
          {({ isUserActive }) => {
            const showControls = isUserActive || !isPlaying;
            return (
              <Container
                isFullScreen={isPlayerFullscreen}
                ref={videoContainerNode}
              >
                <VideoContainer isFullScreen={isPlayerFullscreen}>
                  <Video
                    isFullScreen={isPlayerFullscreen}
                    autoPlay={shouldAutoplay}
                    ref={videoNode}
                    src={trailerUrl}
                  />
                </VideoContainer>

                {isAtEndOfTrailer && isFilmTrailer && (
                  <FadeIn isShowing>
                    <PreviewClipEndOfClipOverlay
                      film={filmOrFilmGroup as Film}
                    />
                  </FadeIn>
                )}
                {isLoading ? (
                  <LoadingContainer>
                    <Loading color={themeColors.white} />
                  </LoadingContainer>
                ) : (
                  <>
                    <FadeInAndOut isShowing={showControls}>
                      <FadeInAndOut
                        isShowing={!hidePlayButton}
                        fadeSeconds={0.2}
                      >
                        <PlayButtonContainer>
                          <TextPlayButton
                            text={t('film_player:film_player.trailer_upcase')}
                            onClick={togglePlaying}
                            isPlaying={isPlaying}
                          />
                        </PlayButtonContainer>
                      </FadeInAndOut>
                      <RightSideControls>
                        <ShareButtons
                          url={shareButtonsUrl}
                          shareCopy={shareButtonsCopy}
                          onOpenChange={onShareButtonOpenChange}
                          trackShareButtonClick={doTrackTrailerEvent}
                          snowplowElement="film_trailer_player"
                        />
                        {isFullscreenSupported && (
                          <FullScreenButtonContainer>
                            <FullScreenButton
                              toggleFullScreenState={() => {
                                setIsFullScreen(!isFullScreen);
                              }}
                              player={playerInterface}
                            />
                          </FullScreenButtonContainer>
                        )}
                      </RightSideControls>
                    </FadeInAndOut>
                    <ProgressBarFadeInOutContainer isShowing={showControls}>
                      <ProgressBar
                        barHeight={progressBarHeight}
                        playerControlInterface={playerInterface}
                        dragging={undefined}
                        draggingRef={undefined}
                        setDragging={undefined}
                        isLoading={undefined}
                        handleIsSeekingOnProgressBar={() => {}}
                      />
                    </ProgressBarFadeInOutContainer>
                  </>
                )}
              </Container>
            );
          }}
        </UserActiveContainer>
      </InPagePlayerKeyShortcuts>
    </StyledStopPropagation>
  );
};

export const getIsFilmTrailer = (filmOrFilmGroup: Film | FilmGroup) =>
  'optimised_trailers' in filmOrFilmGroup;

const progressBarHeight = '8px';

const StyledStopPropagation = styled(StopPropagation)`
  // prevent video overflowing container when < max-height https://stackoverflow.com/a/36247448/2368141
  // use 207px over 0 so the fullscreen keyboard shortcut works
  min-height: 207px;
  height: initial;
`;

const responsiveMaxHeightVideoStyles = ({ isFullScreen, theme }) =>
  isFullScreen
    ? 'max-height: 100%;'
    : css`
        max-height: 207px;
        @media (min-width: ${theme.mqNew.tablet}) {
          max-height: 400px;
        }
        @media (min-width: ${theme.mqNew.desktop}) {
          max-height: 529px;
        }
        @media (min-width: ${theme.mqNew.wide}) {
          max-height: 631px;
        }
      `;

const Container = styled.div<{ isFullScreen: boolean }>`
  position: relative;
  background: ${props => props.theme.color.black};
  height: 100%;
  ${responsiveMaxHeightVideoStyles}
`;

const Video = styled.video<{ isFullScreen: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  ${responsiveMaxHeightVideoStyles}
`;

const fullVideoContainerStyles = css`
  width: 100%;
  height: 100vh;
  object-fit: contain;
`;

const VideoContainer = styled.div<{ isFullScreen: boolean }>`
  ${props => (props.isFullScreen ? fullVideoContainerStyles : 'height: 100%;')}
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayButtonContainer = styled.div`
  position: absolute;
  bottom: ${progressBarHeight};
  left: 0;
  padding: 15px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 23px 24px;
  }
`;

const RightSideControls = styled.div`
  position: absolute;
  right: 0;
  bottom: ${progressBarHeight};
  padding: 15px;
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 23px 24px;
  }
`;

const FullScreenButtonContainer = styled.div`
  margin: 0 0 0 20px;
`;

const fadeOutOpacity = css`
  opacity: 0;
`;

/**
 * Using a custom style here, as @app/components/FadeInAndOut uses
 * "display: none" which affects the layout, moving the trailer a little
 * It also stops the ProgressBar from rerendering when not showing (which
 * is noticible if controls faded out at the end of a trailer)
 */
const ProgressBarFadeInOutContainer = styled.div<{ isShowing: boolean }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: opacity 0.5s;
  opacity: 1;
  ${props => !props.isShowing && fadeOutOpacity}
`;

export default TrailerPlayer;
