import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import {
  PauseButtonIcon,
  PlayButtonIcon,
} from '@app/components/icons/PlayerIcons';
import Link from '@app/components/Link';

const buttonThemes = {
  black: {
    border: themeColors.black,
    background: 'transparent',
    content: themeColors.black,
    borderHover: themeColors.mubiBlue,
    backgroundHover: themeColors.mubiBlue,
    contentHover: themeColors.white,
  },
  dark: {
    border: themeColors.lightText,
    background: 'transparent',
    content: themeColors.lightText,
    borderHover: themeColors.mubiBlue,
    backgroundHover: themeColors.mubiBlue,
    contentHover: themeColors.white,
  },
  light: {
    border: themeColors.white,
    background: 'transparent',
    content: themeColors.white,
    borderHover: themeColors.mubiBlue,
    backgroundHover: themeColors.mubiBlue,
    contentHover: themeColors.white,
  },
  free: {
    border: themeColors.white,
    background: 'transparent',
    content: themeColors.white,
    borderHover: themeColors.free,
    backgroundHover: themeColors.free,
    contentHover: themeColors.white,
  },
  white: {
    border: 'none',
    background: themeColors.white,
    content: themeColors.black,
    borderHover: 'none',
    backgroundHover: themeColors.mubiBlue,
    contentHover: themeColors.white,
    shadow: true,
  },
} as const;

type buttonThemeTypes = keyof typeof buttonThemes;

type TextPlayButtonProps = {
  text: string;
  color?: buttonThemeTypes;
  isPlaying?: boolean;
  onClick?: () => void;
  href?: string;
  playIconWidth?: string;
  pauseIconWidth?: string;
  textIconGap?: string;
  className?: string;
  isSpaLink?: boolean;
};

const TextPlayButton = ({
  text,
  color = 'light' as buttonThemeTypes,
  isPlaying = null,
  onClick = () => {},
  href = null,
  playIconWidth = '15px',
  pauseIconWidth = '11px',
  textIconGap = '13px',
  className = '',
  isSpaLink = false,
}: TextPlayButtonProps) => {
  const buttonTheme = Object.keys(buttonThemes).includes(color)
    ? buttonThemes[color]
    : buttonThemes.dark;

  const button = (
    <ButtonContainer
      id="watch-now-button"
      color={buttonTheme}
      onClick={onClick}
      className={className}
    >
      <PlayButtonIconContainer textIconGap={textIconGap}>
        {isPlaying ? (
          <PauseContainer pauseIconWidth={pauseIconWidth}>
            <PauseButtonIcon width="100%" />
          </PauseContainer>
        ) : (
          <PlayContainer playIconWidth={playIconWidth}>
            <PlayButtonIcon width="100%" />
          </PlayContainer>
        )}
      </PlayButtonIconContainer>
      <FilmPlayButtonLinkText>{text}</FilmPlayButtonLinkText>
    </ButtonContainer>
  );

  if (href) {
    if (isSpaLink) {
      return <StyledLink href={href}>{button}</StyledLink>;
    }
    return <StyledHardLink href={href}>{button}</StyledHardLink>;
  }

  return button;
};

export default TextPlayButton;

const PlayButtonIconContainer = styled.div<{ textIconGap: string }>`
  margin-right: ${props => props.textIconGap};
`;

const PlayContainer = styled.div<{ playIconWidth: string }>`
  width: ${props => props.playIconWidth};
`;
const PauseContainer = styled.div<{ pauseIconWidth: string }>`
  width: ${props => props.pauseIconWidth};
  margin-right: 4px;
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledHardLink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

const ButtonContainer = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 2px;
  background-color: ${props => props.color.background};
  color: ${props => props.color.content};

  height: 45px;
  padding: 0 20px 0 17px;
  font-size: 16px;

  border: 1px solid ${props => props.color.border};
  border-radius: 23px;

  transition: color 0.15s, background-color 0.15s;

  path {
    fill: ${props => props.color.content};
    transition: fill 0.15s;
  }

  ${props =>
    props.color.shadow
      ? 'filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));'
      : null}

  @media ${props => props.theme.helpers.supportsHoverMq} {
    &:hover {
      background-color: ${props => props.color.backgroundHover};
      color: ${props => props.color.contentHover};
      border: 1px solid ${props => props.color.borderHover};

      path {
        fill: ${props => props.color.contentHover};
      }
    }
  }
`;

const FilmPlayButtonLinkText = styled.div`
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.1px;
`;
