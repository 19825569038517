import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { FocalPoint } from '@app/api/resources/Film';
import { ObjectOfStrings } from '@app/api/utility-types';

export type FilmGroupId = number;

export type FilmGroup = {
  id: FilmGroupId;
  slug: string;

  title: string;
  title_color: string;
  title_treatment_url: string;
  title_upcase: string;
  full_title: string;
  full_title_upcase: string;
  hide_title_on_splash: boolean;

  subtitle: string;
  subtitle_color: string;
  subtitle_upcase: string;

  description: string;
  description_html: string;
  short_description: string;
  short_description_html: string;

  color: string;
  image: string;
  is_season: boolean;
  average_colour_hex: string;
  design_variant: string;
  double_bill: boolean;
  focal_point: FocalPoint;
  trailers: {
    profile: string;
    url: string;
  }[];
  published: boolean;
  total_items: number;
  web_url: string;
  portrait_image: {
    url: string;
    focal_point: FocalPoint;
  };
  librarySpecialsSlug?: string;
};

export type Collection = {
  id: number;
  canonical_url: string;
  film_count: number;
  full_title: string;
  image_average_colour: string;
  image_url: string;
  image_urls: {
    large: string;
    medium: string;
    small: string;
    square: string;
  };
  is_series: boolean;
  playable: boolean;
  title_locale: string;
};

const getFilmGroupsSpotlightsUrl = () => '/film_groups/spotlights';

const getFilmGroupHighlightsUrl = () =>
  '/film_groups/highlights?include_continue_watching=true&include_your_watchlist=true';

export const getFilmGroupHighlights = (
  httpContext: ObjectOfStrings,
): Promise<{
  data: FilmGroup[];
}> => HttpService(httpContext).get(getFilmGroupHighlightsUrl());

export const getFilmGroupSpotlights = (
  httpContext: ObjectOfStrings,
): Promise<{
  data: FilmGroup[];
}> => HttpService(httpContext).get(getFilmGroupsSpotlightsUrl());

export const getRelatedFilmGroups = (
  httpContext: ObjectOfStrings,
  filmGroupId: number,
): Promise<{ data: FilmGroup[] }> =>
  HttpService(httpContext).get(`/film_groups/${filmGroupId}/related`);

export const getFilmGroupsForFilm = (
  httpContext: ObjectOfStrings,
  filmId: number,
): Promise<{
  data: {
    film_groups: FilmGroup[];
    meta: Meta;
  };
}> => HttpService(httpContext).get(`/films/${filmId}/film_groups`);

export const getFilmGroupTrailerUrlAtProfile = (
  filmGroupSpotlight: FilmGroup,
  profile: string,
) =>
  filmGroupSpotlight?.trailers?.find(trailer => trailer.profile === profile)
    ?.url;
