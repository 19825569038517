import { ReactNode, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';

import {
  PlayerControlInterface,
  shouldPreventEventFromFiring,
} from '@app/services/player';

import useStateAndRef from '@app/hooks/utils/useStateAndRef';

type InPagePlayerKeyShortcutsProps = {
  children: ReactNode;
  userHasInteractedWithPlayer: boolean;
  playerControlInterface?: PlayerControlInterface;
};

const InPagePlayerKeyShortcuts = ({
  children,
  playerControlInterface = null,
  userHasInteractedWithPlayer,
}: InPagePlayerKeyShortcutsProps) => {
  const [, isPlayerVisibleRef, setIsPlayerVisible] = useStateAndRef(false);
  const [initialisedKeyListener, setInitialisedKeyListener] = useState(false);

  useEffect(() => {
    if (
      playerControlInterface &&
      userHasInteractedWithPlayer &&
      !initialisedKeyListener
    ) {
      setInitialisedKeyListener(true);
      addKeyDownListenerToPlayer();
    }
    return () => {
      removeKeyDownListenerFromPlayer();
    };
  }, [playerControlInterface, userHasInteractedWithPlayer]);

  const onPlayerVisible = isVisible => {
    setIsPlayerVisible(isVisible);
  };

  const handleKeyPress = e => {
    if (playerControlInterface && isPlayerVisibleRef.current) {
      switch (e.code) {
        case 'Space':
          // Prevent default space behaviour for page scroll and
          // repeating the play button action if focus is the play button
          if (
            e?.target === document.body ||
            e?.target?.id === 'watch-now-button'
          ) {
            e.preventDefault();
          }
          try {
            playerControlInterface.doTogglePlay();
          } catch (error) {
            // Ignoring error when browsers prevent play from occurring.
          }
          break;
        case 'ArrowLeft': {
          if (!shouldPreventEventFromFiring(e)) {
            const tenSecondsPrior = Math.max(
              playerControlInterface.getCurrentTime() - 10,
              0,
            );
            playerControlInterface.setCurrentTime(tenSecondsPrior);
          }
          break;
        }
        case 'ArrowRight': {
          if (!shouldPreventEventFromFiring(e)) {
            const tenSecondsLater =
              playerControlInterface.getCurrentTime() + 10;
            if (tenSecondsLater < playerControlInterface.getDuration()) {
              playerControlInterface.setCurrentTime(tenSecondsLater);
            }
          }
          break;
        }
        case 'KeyF':
          if (!shouldPreventEventFromFiring(e)) {
            playerControlInterface.doToggleFullscreen();
          }
          break;
        default:
          break;
      }
    }
  };

  const addKeyDownListenerToPlayer = () => {
    document.addEventListener('keydown', handleKeyPress);
  };

  const removeKeyDownListenerFromPlayer = () => {
    document.removeEventListener('keydown', handleKeyPress);
  };

  return (
    <InView onChange={onPlayerVisible} rootMargin="100px 0px 100px 0px">
      {children}
    </InView>
  );
};

export default InPagePlayerKeyShortcuts;
