import { ReactNode } from 'react';

type StopPropagationProps = {
  children: ReactNode;
  className?: string;
};

const StopPropagation = ({
  children,
  className = '',
}: StopPropagationProps) => (
  <div className={className} onClick={e => e.stopPropagation()}>
    {children}
  </div>
);

export default StopPropagation;
