import { ReactNode, useState } from 'react';
import styled from '@emotion/styled';

import { isGreaterThanMobileWidth } from '@app/services/breakpoints';

type ElementOnHoverScalerProps = {
  children: ReactNode;
  forceScaleUp?: boolean;
};

const ElementOnHoverScaler = ({
  children,
  forceScaleUp = false,
}: ElementOnHoverScalerProps) => {
  const [scaleUp, setScaleUp] = useState(false);

  const doSetScaleUp = enlarge => {
    if (isGreaterThanMobileWidth()) {
      setScaleUp(enlarge);
    }
  };

  const isForceScaled = forceScaleUp && isGreaterThanMobileWidth();

  return (
    <Container
      scaleUp={scaleUp || isForceScaled}
      onMouseEnter={() => doSetScaleUp(true)}
      onMouseLeave={() => doSetScaleUp(false)}
    >
      {children}
    </Container>
  );
};

export default ElementOnHoverScaler;

const Container = styled.div<{ scaleUp: boolean }>`
  cursor: pointer;
  transition: transform 0.15s;
  transform: ${props => (props.scaleUp ? 'scale(1.4)' : 'scale(1)')};
`;
